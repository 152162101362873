export default function generalReportUrl(filename, query, type) {
  type = type || "preview";
  let url = `/report/ureport/${type}?_u=file:${filename}.xml&_r=1&`;

  let arr = [];

  if (typeof query === 'object') {
    arr = Object.keys(query).map((x)=>{
      let val = ''
      if (typeof query[x] !== 'undefined') {
        val = query[x];
      }
      if (val && typeof val == 'string' && val.indexOf('#') > -1) { // 将参数值中的 "#" 转码为%23
        val = val.replace(/#/ig, '%23');
      }
      return `${x}=${val}`;
    })
  } else {
    throw new Error('查询参数错误')
  }
  url += arr.join('&');
  return encodeURI( url );
}