// WaGen___HASHKEY__d6a340d1_2021-09-27 16:39:59 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
未提供注释
*/
export function ydhxxcxService_getYhxxTreeAll(jgbm,dybm,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmszhcx/service/YdhxxcxService/getYhxxTreeAll',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function ydhxxcxService_getYhzdxxAndSfqdxx(jgbm,yhbh,ywbh,zwyf, meta) {
	return fetch({
		url : 'psdmszhcx/service/YdhxxcxService/getYhzdxxAndSfqdxx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh, //String
				ywbh : ywbh, //Integer
				zwyf : zwyf //Integer
			}
		}
	})
}

/* ---
查询用户电价套餐信息
*/
export function ydhxxcxService_getYhDjtc(jgbm,yhbh,ywlsh,jlfl,paginationInfo, meta) {
	return fetch({
		url : 'psdmszhcx/service/YdhxxcxService/getYhDjtc',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh, //String
				ywlsh : ywlsh, //Long
				jlfl : jlfl, //String
				paginationInfo : paginationInfo //PaginationInfo
			}
		}
	})
}

/* ---
查询用户计量点装置表底
*/
export function ydhxxcxService_getYhJldzzbd(jgbm,yhbh,ywlsh,jlfl,paginationInfo, meta) {
	return fetch({
		url : 'psdmszhcx/service/YdhxxcxService/getYhJldzzbd',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh, //String
				ywlsh : ywlsh, //Long
				jlfl : jlfl, //String
				paginationInfo : paginationInfo //PaginationInfo
			}
		}
	})
}

/* ---
查询配网设备变压器信息
*/
export function ydhxxcxService_getPwsbByqxx(jgbm,yhbh,ywlsh,jlfl,paginationInfo, meta) {
	return fetch({
		url : 'psdmszhcx/service/YdhxxcxService/getPwsbByqxx',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				yhbh : yhbh, //String
				ywlsh : ywlsh, //Long
				jlfl : jlfl, //String
				paginationInfo : paginationInfo //PaginationInfo
			}
		}
	})
}

