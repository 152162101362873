<template>
<page-view title="算费清单" left-arrow :nav-border="false" custom-class="sfqdPage">
  <loading-layout ref="loadingLayout" :error-text="errorText" empty-text="未查询到数据">
    <div class="jbxxBox">
      <dl>
        <dt>用户编号:</dt>
        <dd>{{zdxx.yhbh}}</dd>
      </dl>
      <dl>
        <dt>用户名称:</dt>
        <dd>{{zdxx.yhmc}}</dd>
      </dl>
      <dl>
        <dt>账务月份:</dt>
        <dd>{{zdxx.zwyf}}</dd>
      </dl>
    </div>
    <div class="box">
      <div class="jldInfo">
        <van-row :gutter="10">
          <van-col span="12">
            <p>启动日期：<span>{{currentRow.qdrq | qdrqFilter}}</span></p>
          </van-col>
          <van-col span="12">
            <p>业务编号：<span>{{currentRow.ywbh}}</span></p>
          </van-col>
        </van-row>
        <van-row :gutter="10">
          <van-col span="12">
            <p>计量点号：<span>{{currentRow.jldh}}</span></p>
          </van-col>
          <van-col span="12">
            <p>业务类型：<span>{{currentRow.ywlxmc}}</span></p>
          </van-col>
        </van-row>
        <van-row :gutter="10">
          <van-col span="12">
            <p>电量：<span>{{currentRow.bczjfdl}}</span></p>
          </van-col>
          <van-col span="12">
            <p>电费：<span>{{currentRow.bcysdf}}</span></p>
          </van-col>
        </van-row>
      </div>
      
       
       <div class="iframe-wrapper">
        <div class="iframe-header clearfix">
          <div class="float-right">
            <van-button type="default mr-10" size="mini" @click="downloadPDF">导出PDF</van-button>
            <van-button type="default" size="mini" @click="downloadExcel">导出Excel</van-button>
          </div>
          <span class="inline-title">算费清单</span>
        </div>
        <div :class="['iframe-body',{'loading': bbLoading}]">
          <div class="loading-box">
            <van-loading size="24" vertical :style="{marginTop: tableHeight * 0.4+'px'}">加载中...</van-loading>
          </div>
          <iframe ref="ifra" class="sfqd-bbiframe" frameborder="0" :style="{width: '100%', height: tableHeight +'px'}"></iframe>
        </div>
       </div>
       
       <div class="paginationBox">
          <van-button type="primary" size="small" @click="handlePrevItem" :disabled="currentIndex == 0 ? true : false">上一页</van-button>
          <span>{{currentIndex + 1}}/{{tableData.length}}</span>
          <van-button type="primary" size="small" @click="handleNextItem" :disabled="currentIndex == tableData.length - 1 ? true : false">下一页</van-button>
       </div>
    </div>
  </loading-layout>
</page-view>
</template>

<script>
import { mapGetters } from 'vuex';
import { ydhxxcxService_getYhzdxxAndSfqdxx } from '@/api/psdmszhcx/service/YdhxxcxServiceAPI'

import generalReportUrl from '@/utils/generalReportUrl.js';
export default {
  name: 'sfqd',
  components: {
  },
  data() {
    return {
      zdxx: {
        jgbm: '',
        yhbh: '',
        ywbh: '',
        zwyf: ''
      },
      errorText: '',
      tableData: [], // 计量点利恩
      currentRow: {}, // 当前选中计量点
      currentIndex: 0,
      tableHeight: 0,
      bbLoading: false
    }

  },

  computed: {
    ...mapGetters(['token','jgbm','czyh']),
    iframeSrc() {
      return this.getReportUrl("preview")
    }
  },
  filters: {
    qdrqFilter(val) {
      if (val) {
        return val.split(" ")[0]
      }
      return "-"
    }
  },

  methods: {
     getReportFilename(fsbz = '') {
      let fileName = 'sfqd_new.ureport'
      if(fsbz == '1'){
        fileName = 'sfqd_new.ureport'
      }
      return fileName;
    },

    getReportUrl(type) {
       if (this.currentRow && this.currentRow.jldh) {
        var obj={
          jldh:this.currentRow.jldh,
          qdrq:this.currentRow.qdrq,
          yhbh:this.currentRow.yhbh,
          ywbh:this.currentRow.ywbh,
        };
        let fsbz = this.currentRow.fsbz ? this.currentRow.fsbz : (this.zdxx.fsbz || '');
        return generalReportUrl(this.getReportFilename(fsbz), obj, type);
      } else {
        return '';
      }
    },
    fetchData() {
      console.log(this.zdxx);
      let {jgbm, yhbh,ywbh,zwyf} = this.zdxx;
      this.tableData = [];
      if (ywbh == 0) {
        ywbh = null;
      }
      let lv = this.$refs['loadingLayout'];
      lv.setStatus("loading");
      ydhxxcxService_getYhzdxxAndSfqdxx(jgbm,yhbh,ywbh,zwyf).then(res => {
        if (res.status && res.status == 400) {
          throw new Error(res.message);
        }
        if (res.content && res.content.status == 400) {
          throw new Error(res.content.message);
        }
        
        this.tableData = res.content.data;
        if (this.tableData.length) {
          lv.setStatus("success");
          this.$nextTick(() => {
            this.currentRow = this.tableData[0];
            this.currentIndex = 0;
          });
        } else {
          lv.setStatus("empty");
        }
      }).catch(err => {
        this.$toast(err.message);
        this.errorText = err.message;
        lv.setStatus("error");
      });
    },
    handlePrevItem() {
      if (this.tableData.length && this.currentIndex > 0) {
        this.$nextTick(() => {
          this.currentIndex -= 1;
          this.currentRow = this.tableData[this.currentIndex];
        });
      }
    },
    handleNextItem() {
      if (this.tableData.length && this.currentIndex < this.tableData.length - 1) {
        this.$nextTick(() => {
          this.currentIndex += 1;
          this.currentRow = this.tableData[this.currentIndex];
        });
      }
    },
    downloadPDF() {
      // http://localhost:8080/report/ureport/pdf?_u=file:sfqd_new.ureport.xml&_r=1&_t=1,2,3,4,5&jldh=10202673&qdrq=2022-06-01%2000:00:00&yhbh=3002183729&ywbh=31002352
      let pdfurl = this.getReportUrl("pdf");
      window.open(pdfurl, "_blank");
    },
    downloadExcel() {
      let uri = this.getReportUrl("excel");
      window.open(uri, "_blank");
    }
  },

  mounted() {
    if (this.$route.query) {
      this.zdxx = this.$route.query;
    }
    if (!this.zdxx.jgbm) {
      this.zdxx.jgbm = this.jgbm;
    }
    this.tableHeight = document.body.clientHeight - 365;
    this.fetchData();
    const vm = this;
    const iframe = this.$refs['ifra'];
    if (iframe.attachEvent) {
      iframe.attachEvent("onload", function() {
        console.log("1212")
      });
    } else {
      iframe.onload = function() {
        vm.bbLoading = false;
      };
    }
  },

  watch: {
    iframeSrc: {
      handler(val, oldVal) {
        if (val && val != oldVal) {
          console.log(this, val)
          this.$refs['ifra'].contentWindow.location.replace(val);
          this.$nextTick(function() {
            
          });
          this.bbLoading = true;
        }
      },
      immediate: true
    },
  },
}
</script>

<style lang="scss" scoped>
  $top-bg-color: #00b1ff;
  .sfqdPage /deep/ {
    .van-nav-bar {
      background-color: $top-bg-color;
      color: #fff;
    }
    .van-nav-bar .van-icon,
    .van-nav-bar__title {
      color: #fff;
    }

    .jbxxBox {
      background-color: $top-bg-color;
      position: relative;
      color: #fff;
      font-size: 14px;
      padding: 10px 15px 20px 15px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/assets/images/home/city-shadow.png');
        background-position: bottom;
        background-size: auto 90%;
        background-repeat: no-repeat;
        opacity: 0.13;
      }
      dl {
        margin: 0;
        line-height: 26px;
        display: flex;
      }
      dt {
        width: 5em;
        display: inline-block;
        font-weight: bold;
      }
      dd {
        margin-left: 10px;
      }
    }
    .box {
      margin: 10px;
      border-radius: 6px;
      padding: 10px;
      background: #fff;
    }

    .jldInfo {
      font-size: 13px;
      line-height: 1.5;
      padding-bottom: 10px;
      border-bottom: 1px dashed #8c8c8c;

      p {
        color: #8c8c8c;
      }
      span {
        color: #333;
      }
    }

    .iframe-wrapper {
      margin: 10px 0;
      .iframe-header {
        padding: 5px 0;
      }
      .inline-title {
        display: inline-block;
        line-height: 30px;
        height: 30px;
        &::before {
          top: 8px;
        }
      }
    }

    .iframe-body {
      .loading-box {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(255,255,255,0.89);
      }
      position: relative;
      &.loading .loading-box {
        display: block;
      }
    }

    .paginationBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  } 
</style>